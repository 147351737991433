.chartpreview-box {
    background-color: #f3f3f4;
    padding-bottom: 15px;
}

.dataFilterField {
    margin-bottom: 20px;
}
.titleFieldRow {
    padding: 10px;
    height: 50px;
    padding-left: 20px;
    margin-top: 20px;
    text-align: center;
    vertical-align: middle;
    line-height: 90px; 
}

.top-box {
    height: 290px;
    max-height: 290px;
}

.bottom-box {
    min-height: 260px;
}

.selectBox {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    height: 56px;
    padding-left: 20px;
    margin-top: 20px;
}